<template lang="pug">
main
    .main-container
        .logo-container
            img(:src="require('@/assets/img/logo-solo.svg')",alt="Time Square Logo")
        .welcome-text WELCOME TO TIME SQUARE CLUB, RESORT & SPA
        h1 Privacy Policy
    .container <b>Collection of Personal Information:</b><br/>Time square club may collect personal information from its users, such as name, email address, and postal address, when it is voluntarily provided through the website or through other means.
        <br/>
        <br/><b>Use of Personal Information:</b><br/>Time square club may use personal information to provide its services, communicate with its users, or for marketing and research purposes. Personal information will not be sold or shared with third parties without the user's consent.
        <br/>
        <br/><b>Security of Personal Information:</b><br/>Time square club takes the security of its users' personal information seriously and has implemented appropriate technical and organizational measures to protect it.
        <br/>
        <br/><b>Data Retention:</b><br/>Time square club will retain personal information for as long as it is necessary to fulfil the purposes for which it was collected or as required by law.
        <br/>
        <br/><b>Access to Personal Information:</b><br/>Users have the right to access, rectify, or erase their personal information held by time square club. Users may exercise these rights by contacting time square club customer support.
        <br/>
        <br/><b>Use of Cookies:</b><br/>Time square club may use cookies to improve the user experience on its website and for analysis and marketing purposes. Users may choose to disable cookies in their browser settings, but this may limit their ability to use certain features of the website.
        <br/>
        <br/><b>Changes to Privacy Policy:</b><br/>Time square club reserves the right to make changes to its privacy policy at any time without notice. Any changes will be posted on the website and will become effective immediately.
        <br/>
        <br/><b>Dispute Resolution:</b><br/>Any dispute arising from or related to time square club's privacy policy will be resolved through arbitration in accordance with the laws of India.
        <br/>
        <br/><b>Contact Information:</b><br/>For questions or concerns regarding time square club's privacy policy, please contact its customer support team.

</template>
    
<script>

import DisplayHeader from '../components/DisplayHeader.vue'

export default {
    name: "TermsConditions",
    components: {
    DisplayHeader
    },
    mounted() {
        window.scroll(0,0);
    }
}
</script>

<style scoped>
.main-container {
    color: black;
    background-color: #d7d5d0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    margin-bottom: 80px;
}

.logo-container img {
    width: 3rem;
    margin: 1rem;
}

.welcome-text {
    margin: 1rem;
    margin-top: 0;
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    opacity: 0.5;
    font-weight: 400;
}

h1 {
    margin: 0;
    width: 80%;
    font-family: "DM Serif Display",serif;
    font-size: 3rem;
    text-align: center;
    font-weight: 400;
}

</style>